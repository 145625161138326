import styles from "../components/index.page.module.scss"
import { useState, useRef, useEffect } from 'react'
import { signIn, signOut, useSession, getSession } from "next-auth/react"

export async function getServerSideProps(context) {

  const session = await getSession(context);

  if (session) {
    return {
      redirect: {
        permanent: false,
        destination: "/app",
      }
    }
  } else {
    return {
      props: {}
    };
  }
}


export default function Index({  }) {
  return(
    <div className={styles.container}>
      <h1><img src="/images/logo.svg" /></h1>

      <p>Create AI images</p>
      <p>Currently in private alpha. <a href="https://tally.so/r/wd1LNn">Apply for an invite</a>.</p>

      <button
        href={`/api/auth/signin`}
        className={styles.buttonPrimary}
        onClick={(e) => {
          e.preventDefault()
          signIn()
        }}
      >
        Sign in
      </button>      
    </div>


    
  )
}

